import { render, staticRenderFns } from "./TermsManage.vue?vue&type=template&id=7c4dfb31&"
import script from "./TermsManage.vue?vue&type=script&lang=js&"
export * from "./TermsManage.vue?vue&type=script&lang=js&"
import style0 from "./TermsManage.vue?vue&type=style&index=0&id=7c4dfb31&lang=scss&"
import style1 from "./TermsManage.vue?vue&type=style&index=1&id=7c4dfb31&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\Bitnami\\jenkins-2.89.4-1\\apps\\jenkins\\jenkins_home\\jobs\\liveinfo3-front-admin-dev\\workspace\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7c4dfb31')) {
      api.createRecord('7c4dfb31', component.options)
    } else {
      api.reload('7c4dfb31', component.options)
    }
    module.hot.accept("./TermsManage.vue?vue&type=template&id=7c4dfb31&", function () {
      api.rerender('7c4dfb31', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/apps/others/terms-manage/TermsManage.vue"
export default component.exports